.root {
  text-align: center;
  color: orange;
  background-color: black;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  padding: 10px;
  box-sizing: border-box;
}

.button {
  display: inline-block;
  border: 1px solid white;
  padding: 5px;
  border-radius: 3px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #aa4422;
  }

  &:active {
    background-color: white;
  }
}

.missions {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  white-space: pre;
  gap: 10px;
  text-align: left;
  // font-family: monospace;
  // font-weight: light;
  // font-size: 2em;
  color: orange;
}
