@use "sass:color";

$defaultColor: orange;
$selectedColor: yellow;

$padding: 5px;

@function colorizeSVG($color) {
  @return (
    grayscale(100%) brightness(color.lightness($color) * 0.9) sepia(1)
      saturate(color.saturation($color) * 3) hue-rotate(-45deg)
      hue-rotate(color.hue($color))
  );
}

.root {
  border: 1px solid $defaultColor;
  margin-bottom: 25px;
  font-family: "Euro Caps";

  header {
    border-bottom: 1px solid $defaultColor;
    background-color: $defaultColor;
    color: black;
    padding: $padding;
    font-size: 1.2em;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    color: $defaultColor;
    padding: $padding;

    &:not(:last-child) {
      border-bottom: 1px solid $defaultColor;
    }
  }

  .missionTypeIcon,
  .stationTypeIcon {
    width: 1.2em;
    max-height: 0.66em;
    filter: colorizeSVG($defaultColor);
  }

  .missionCount {
    font-size: 0.5em;
    position: relative;
    top: 3px;
  }

  &.current {
    border-color: $selectedColor;

    header {
      background-color: $selectedColor;
      border-color: $selectedColor;
    }

    li {
      color: $selectedColor;
      border-color: $selectedColor;
    }

    .missionTypeIcon,
    .stationTypeIcon {
      filter: colorizeSVG($selectedColor);
    }
  }
}
